import * as React from 'react';
import 'components/Navigation.css';
import { AuthContext } from "AuthContext";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from "react-router-dom";
import AppLogo from 'assets/logo-atfom.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid } from '@mui/material';

const pages = ['Planlegg', 'Referat', 'Arkiv'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navigation() {
  
  const navigate = useNavigate(); 
  const { user, signOut } = React.useContext(AuthContext)

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleProfileClick = () => {
    navigate("/profile") 
  }
  const handleOrganizationsClick = () => {
    navigate("/organizations") 
  }
  const handleOrganizationClick = () => {
    navigate("/organization") 
  }
  const handleHomeClick = () => {
    navigate("/") 
  }
  const handleArchiveClick = () => {
    navigate("/archive") 
  }
  const handleLoginClick = () => {
    navigate("/login") 
  }
  const handleSignupClick = () => {
    navigate("/signup") 
  }


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box justifyContent={'left'} sx={{ display: { xs: 'none', md: 'flex' }}}>
          <img onClick={handleHomeClick} src={AppLogo} alt="logo" className="TopLogo" />
          </Box>
          {user && (
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="arkiv" onClick={handleArchiveClick}>
                <Typography textAlign="center">Arkiv</Typography>
              </MenuItem>
            </Menu>
          </Box>
              )}

          <Box onClick={handleHomeClick} justifyContent={'center'} sx={{ flexGrow: 100, display: { xs: 'flex', md: 'none' } }}>
          <img onClick={handleHomeClick} src={AppLogo} alt="logo" className="TopLogo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {user && (
          
              <Button
                key="arkiv"
                onClick={handleArchiveClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Arkiv
              </Button>
            )}

          </Box>
          {!user && (
        <Box sx={{ display: { xs: 'flex', md: 'flex', spacing: 1 } }}>
        <Button
          key="login"
          onClick={handleLoginClick}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Login
        </Button>
        <Button
          key="signup"
          onClick={handleSignupClick}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Signup
        </Button>

          </Box>
          )}
          {user && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <MenuItem key="organizations" onClick={handleOrganizationsClick}>
              <Typography textAlign="center">Organisasjoner</Typography>
            </MenuItem>
                <MenuItem key="profile" onClick={handleProfileClick}>
                  <Typography textAlign="center">Konto</Typography>
                </MenuItem>
                <MenuItem key="logout" onClick={signOut}>
                  <Typography textAlign="center">Logg ut</Typography>
                </MenuItem>
              
            </Menu>
          </Box>
        )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navigation;