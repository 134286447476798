import { useState } from "react";
import { signUp } from "service/auth";
import { Box, Button, Grid, TextField } from "@mui/material";

export default function SignUp() {
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setError("")

    try {
      await signUp(username, email, password)
      setSuccess(true)
    } catch (err: unknown) {
      if (err instanceof Error)
        setError(err.message)
    }
  }

  if (success) {
    return (
      <div>
        <h2>SignUp successful!</h2>
        <p>Please check your email for the confirmation code.</p>
      </div>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
            
      <Box className="Login">
      <h2>SignUp</h2>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box>
        <TextField
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        </Box>
        <Box >
        <Button variant="contained" size="large" type="submit">SignUp</Button>
        </Box>
      </form>
      {error && <p>{error}</p>}
    </Grid>

  )
}