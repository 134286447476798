import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
} from "amazon-cognito-identity-js"
import { cognitoConfig } from "cognitoConfig"

export type ProfileData = {
  username: string,
  email: string
}

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
})

export function signUp(username: string, email: string, password: string) {
  const userAttributes: CognitoUserAttribute[] = [
    new CognitoUserAttribute(
      { Name: "email", Value: email }
    )
  ]
  const validationData: CognitoUserAttribute[] = []
  return new Promise((resolve, reject) => {
    userPool.signUp(
      username,
      password,
      userAttributes,
      validationData,
      (err, result: any) => {
        if (err) {
          reject(err)
          return
        }
        resolve(result.user)
      }
    )
  })
}

export function confirmSignUp(username: string, code: string) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    })
  }
  )
}

export function signIn(username: string, password: string) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function forgotPassword(username: string) {
  // Forgot password implementation
}

export function confirmPassword(username: string, code: string, newPassword: string) {
  // Confirm password implementation
}

export function signOut() {
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser) {
    cognitoUser.signOut()
  }
}

export function getCurrentUser() {
  return new Promise<ProfileData>((resolve, reject) => {
    const cognitoUser: CognitoUser | null = userPool.getCurrentUser()

    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        reject(err)
        return
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err)
          return
        }
        if (attributes) {
          const userData = attributes.reduce((acc, attribute: CognitoUserAttribute) => {
            acc[attribute.getName()] = attribute.getValue()
            return acc
          }, {} as Record<string, string>)
          const profile: ProfileData = {
            username: cognitoUser.getUsername(),
            email: userData["email"]
          }
          resolve(profile)
        }
      })
    })
  })
}

export function getSession() {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"))
      return
    }
    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })
}