import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OrganizationsTable from 'components/OrganizationsTable';

function OrganizationsPage() {
    return (
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
            <Box className="Home">
        <h2>Mine  Organisasjoner</h2>
      </Box>
      <Box>
        <OrganizationsTable/>
      </Box>
          </Grid>
    );
  }
export default OrganizationsPage;