import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import SignUp from 'pages/SignUp';
import ConfirmSignUp from 'pages/ConfirmSignUp';
import Login from 'pages/Login';
import UserProfile from 'pages/UserProfile';
import { AuthProvider } from 'AuthContext';
import Footer from "components/Footer";
import Navigation from 'components/Navigation';
import OrganizationsPage from 'pages/OrganizationsPage';
import Archive from 'pages/Archive';
import Home from 'pages/Home';
import OrganizationPage from 'pages/OrganizationPage';

function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Navigation  />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/archive" element={<Archive/>}/>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/confirm-sign-up" element={<ConfirmSignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/organizations" element={<OrganizationsPage />} />
        <Route path="/organization" element={<OrganizationPage />} />
      </Routes>
    </BrowserRouter>
    <Footer
        description="A Tool For Organization Management"
      />
    </AuthProvider>
  );
}


export default App;
