import { useState } from "react";
import { confirmSignUp } from "service/auth";
import { Box, Button, Grid, TextField } from "@mui/material";

export default function ConfirmSignUp() {
  const [username, setUsername] = useState("")
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e:  any) => {
    e.preventDefault()
    setError("")

    try {
      await confirmSignUp(username, code)
      setSuccess(true)
    } catch (err) {
      if (err instanceof Error){
        setError(err.message)
      }
    }
  }

  if (success) {
    return (
      <Grid>
        <h2>Confirmation successful!</h2>
        <p>You can now log in with your user and password!</p>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <h2>Confirm Sign Up</h2>
      <p>Type in your username and the code you have received by email.</p>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          type="text"
          placeholder="Confirmation code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Box>
        <Button variant="contained" type="submit">Confirm</Button>
        </Box>
      </form>
      {error && <p>{error}</p>}
    </Grid>
  )
}