import { useState, useContext } from "react";
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const { user, signIn } = useContext(AuthContext)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setError("")

    try {
      await signIn(username, password)
    } catch (err) {
      if (err instanceof Error){
        setError(err.message)
      }
    }
  }

  // If the user is logged in, don't show the login form
  if (user) {
    // Redirect to the profile page
    return <Navigate to="/" />
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
            
            <Box className="Login">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
      <TextField
          type="text"
          placeholder="Username"
          id="outlined-username"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
            <TextField
          type="password"
          placeholder="Username"
          id="outlined-password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> <br/>
        <Button type="submit" variant="contained" size="large">Login</Button>
      </form>
      {error && <p>{error}</p>}
    </Box>
    </Grid>
  )
}