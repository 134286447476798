import { useContext } from "react";
import { AuthContext } from "AuthContext";
import { Container, Box, Grid, Typography, TextField } from "@mui/material";


export default function UserProfile() {
  const { user, signOut } = useContext(AuthContext)
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
    {user && (
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
    <Box > 
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
          User Profile
        
    </Typography>
    </Box>
    <Box>

        <TextField
          disabled
          id="outlined-disabled"
          label="Username"
          defaultValue={user.username}
        />
    </Box>
    <Box>
        <TextField
          disabled
          id="outlined-disabled"
          label="Email"
          defaultValue={user.email}
        />
    </Box>
    </Grid>
    )}
    </Grid>
  )
}