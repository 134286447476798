import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function Home() {
    return (
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
            
      <Box justifyContent={'center'} justifyItems={'center'} className="Home">
          <h1> Velkommen  til ATFOM</h1>
          <p>ATFOM er et verktøy for å håndtere dokumenter for organisasjoner.</p>
        
      </Box>
          </Grid>
    );
  }
export default Home;